import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../books/IndexContent";

class PhotoMagazineBooksContent extends BaseContentPage {

    constructor(props) {
        super(props, "books-magazine-photo", IndexContent);

        this.state = {
            ...this.state,
            //  filtredBooks: [],
            search: ''
        };
    }

    static books = [


        {
            id: 1,
            title: "FOTOGRAFIA, REVISTA ASOCIATIEI \" FAR \" , ANUL II , NR. 5-6 , 1936",
            publisher: "-",
            year: 1936,
            authors: ["-"],
            pages: 288,
            language: "romana",
            ISBN: "",
            img: "fotografia-revista-asociatiei-far-anul-ii-nr-5-6-1936-p108688-0.jpg"
        },

        {
            id: 2,
            title: "Imagine Magazine Vol. I / 2020",
            publisher: "QLT Studio 2",
            year: 2020,
            authors: ["-"],
            pages: 228,
            language: "romana",
            ISBN: "2734-5718",
            img: "img.png"
        },


        {
            id: 3,
            title: "Imagine Magazine Vol. II / 2020",
            publisher: "QLT Studio 2",
            year: 2020,
            authors: ["-"],
            pages: 226,
            language: "romana",
            ISBN: "2734-5718",
            img: "img_1.png"
        },

        {
            id: 4,
            title: "Imagine Magazine Vol. III / 2020",
            publisher: "QLT Studio 2",
            year: 2020,
            authors: ["-"],
            pages: 0,
            language: "engleza",
            ISBN: "2734-5718",
            img: "imagine-iii.png"
        },


        {
            id: 5,
            title: "Imagine Magazine Vol. IV / 2020",
            publisher: "QLT Studio 2",
            year: 2020,
            authors: ["-"],
            pages: 300,
            language: "engleza",
            ISBN: "2734-5718",
            img: "imagine-iv.jpg"
        },

        {
            id: 6,
            title: "Imagine Magazine Vol. V / 2021",
            publisher: "QLT Studio 2",
            year: 2021,
            authors: ["-"],
            pages: 315,
            language: "engleza",
            ISBN: "2734-5718",
            img: "imagine-v.png"
        },

        {
            id: 7,
            title: "Imagine Magazine Vol. VI / 2021",
            publisher: "QLT Studio 2",
            year: 2021,
            authors: ["-"],
            pages: 299,
            language: "engleza",
            ISBN: "-",
            img: "imagine-vi.png"
        },

        {
            id: 8,
            title: "Imagine Magazine Vol. VII / 2022",
            publisher: "QLT Studio 2",
            year: 2020,
            authors: ["-"],
            pages: 344,
            language: "engleza",
            ISBN: "-",
            img: "imagine-vii.png"
        },

        {
            id: 9,
            title: "Imagine Magazine Vol. VIII / 2024",
            publisher: "QLT Studio 2",
            year: 2024,
            authors: ["-"],
            pages: 290,
            language: "engleza",
            ISBN: "2734-5718",
            img: "IMAGINE-Nr-8-2024.jpg"
        },

        {
            id: 10,
            title: "Avatar Photo Magazine Nr. 1",
            publisher: "-",
            year: 2022,
            authors: ["-"],
            pages: 67,
            language: "romana",
            ISBN: "2821-4927-01",
            img: "1613605409-5.jpeg"
        },

        {
            id: 11,
            title: "Avatar Photo Magazine Nr. 2",
            publisher: "-",
            year: 2022,
            authors: ["-"],
            pages: 67,
            language: "romana",
            ISBN: "2821-4927-02",
            img: "APM-NR-2-724x1024.jpg"
        },

        {
            id: 12,
            title: "Avatar Photo Magazine Nr. 3",
            publisher: "-",
            year: 2023,
            authors: ["-"],
            pages: 67,
            language: "romana",
            ISBN: "2821-4927-03",
            img: "2148909098-0.jpeg"
        },

        {
            id: 13,
            title: "Avatar Photo Magazine Nr. 4",
            publisher: "-",
            year: 2023,
            authors: ["-"],
            pages: 67,
            language: "romana",
            ISBN: "2821-4927-04",
            img: "avatar-photo-magazine-nr-4-revista-educatie-vizuala-avatar-969124.jpeg"
        },

        {
            id: 14,
            title: "Avatar Photo Magazine Nr. 5",
            publisher: "-",
            year: 2024,
            authors: ["-"],
            pages: 67,
            language: "romana",
            ISBN: "2821-4927-05",
            img: "avatar-photo-magazine-nr-4-revista-educatie-vizuala-copie-504909.jpeg"
        },

        {
            id: 15,
            title: "Avatar Photo Magazine Nr. 6",
            publisher: "-",
            year: 2024,
            authors: ["-"],
            pages: 67,
            language: "romana",
            ISBN: "2821-4927-06",
            img: "img_2.png"
        },


    ]

    handleSearch(event) {
        let search = event.target.value;

        // let filtredBooks = ITBooksContent.books.filter((item) =>
        //     item.title.toLowerCase().includes(search.toLowerCase())
        // );
        //
        // this.setState({filtredBooks: filtredBooks});

        this.setState({search: search});
    }

    render() {

        this.state.filtredBooks = PhotoMagazineBooksContent.books;

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <label htmlFor="search">
                        Cautare:&nbsp;
                        <input id="search" type="text" onChange={this.handleSearch.bind(this)}/>
                    </label>
                    &nbsp;
                    <span>Numar total carti: {PhotoMagazineBooksContent.books.length}</span>
                </div>

                <hr/>

                <br/>
                <div className={"text-justify important"}>

                    {PhotoMagazineBooksContent.books
                        .filter(
                            item => {
                                let s = this.state.search.toLowerCase();
                                let okTitle = item.title.toLowerCase().includes(s);
                                let okPublisher = item.publisher.toLowerCase().includes(s);
                                let okAuthors = item.authors.some(a => a.toLowerCase().includes(s));
                                return okTitle || okPublisher || okAuthors;
                            }
                        )
                        .map(function (item, index) {
                            return <div className="row">

                                {/*<div className="col-sm-4">*/}
                                {/*    <img alt={""} style={{width:220}} className={"rounded mx-auto d-block responsive-img"}*/}
                                {/*         src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>*/}

                                {/*</div>*/}

                                <div className="col-sm-12">

                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index + 1}</span>
                                    <table className="table table-sm table-hover table-dark">
                                        <tbody>
                                        <tr>
                                            <td width={250} rowSpan={"7"}>
                                                <img alt={""} style={{width: 220}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/books/' + item.img}/>
                                            </td>

                                            <td width={250}>
                                                <b>Titlu</b>
                                            </td>
                                            <td>
                                                <span className="card-text" dangerouslySetInnerHTML={{__html: item.title}}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Editura</b></td>
                                            <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.publisher}}/></td>
                                        </tr>
                                        <tr>
                                            <td><b>An</b></td>
                                            <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.year}}/></td>
                                        </tr>

                                        <tr>
                                            <td><b>Autori</b></td>
                                            <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.authors}}/></td>
                                        </tr>

                                        <tr>
                                            <td><b>Numar pagini</b></td>
                                            <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.pages}}/></td>
                                        </tr>

                                        <tr>
                                            <td><b>Limba</b></td>
                                            <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.language}}/></td>
                                        </tr>

                                        <tr>
                                            <td><b>ISBN</b></td>
                                            <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.ISBN}}/></td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </div>;

                        })}

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PhotoMagazineBooksContent;