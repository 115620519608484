import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function HC128MuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-hc128";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>


                <b>HC 91 (912)</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_22.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_21.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_20.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_19.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_18.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_17.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_16.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_15.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_14.png'}/>
                </div>

                Status:
                <ul>
                    <li>pe VGA imaginea se da peste cap</li>
                </ul>


                Componente si integrate (HC128 869152570 REV A):
                <ul>
                    <li>Microprocesor original: Zilog Z0840004PSC (Z80 CPU 9346)

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-7/img_23.png'}/>
                        </div>

                    </li>
                    <li>Microprocesor pe placa: Sharp LH0080A

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-7/img_24.png'}/>
                        </div>

                    </li>
                    <li>
                        <li>
                            Trimer (potențiometru ajustabil) – Componenta rotundă cu un șurub de reglaj, utilizată pentru ajustarea fină a unui parametru electric.

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 200}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-7/img_25.png'}/>
                            </div>

                        </li>
                        <li>
                            Cristal de cuarț – Componenta metalică dreptunghiulară marcată cu "17.734475 MHz", utilizată pentru generarea unui semnal de frecvență stabilă.

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 200}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-7/img_26.png'}/>
                            </div>

                        </li>
                        <li>
                            Condensatori ceramici – Componentele albastre de mici dimensiuni, inclusiv cel etichetat „220p”, indicând o capacitate de 220 picofarazi.
                        </li>

                        <li>
                            Memorie EPROM – IC marcat cu <b>M27C512-15F1</b> (B88BY 9418C), utilizat pentru stocarea firmware-ului sau a BIOS-ului.

                            <br/>
                            Memorie EPROM original:
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-7/img_27.png'}/>
                            </div>

                            Memorie EPROM pe placa (B88IC 9235E5 - M27C512-15F1):
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-7/img_28.png'}/>
                            </div>
                        </li>

                        <li>
                            Modulator RF – Componenta metalică dreptunghiulară din colțul dreapta sus, folosită pentru conversia semnalului video pentru un televizor.

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-7/img_29.png'}/>
                            </div>
                        </li>

                        <li>
                            Multiplie circuite integrate logice – IC-uri mici, cel mai probabil din familia TTL (74LSxx), folosite pentru operațiuni logice și controlul semnalelor.
                            <ul>
                                <li>
                                    Circuit integrat cu inscripția SN74LS161AN, produs de Motorola
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_30.png'}/>
                                    </div>
                                    Descriere:
                                    <ul>
                                        <li>
                                            SN74LS161 este un contor binar sincron de 4 biți, utilizat frecvent în circuitele digitale pentru numerotare, generare de adrese și
                                            aplicații de temporizare.
                                        </li>
                                        <li>
                                            Aparține familiei TTL (Transistor-Transistor Logic) cu putere scăzută (LS), ceea ce îl face eficient din punct de vedere energetic.
                                        </li>
                                        <li>
                                            Poate funcționa ca un contor cu încărcare paralelă și dispune de intrări de control pentru resetare și activare.
                                        </li>
                                        <li>
                                            Tensiune de alimentare: 5V (TTL standard).
                                        </li>
                                        <li>
                                            Numărare sincronizată: Funcționează sincronizat cu un semnal de ceas (clock).
                                        </li>
                                        <li>
                                            Capacitate de resetare și presetare: Permite inițializarea numărătorii la un anumit număr
                                        </li>
                                        <li>
                                            Viteză mare: Poate opera la frecvențe relativ ridicate comparativ cu alți contori similari.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Goldstar (LG) GD74LS257A 9241.
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_31.png'}/>
                                    </div>
                                    Descriere:
                                    <ul>
                                        <li>
                                            Este un multiplexor quad 2:1 cu trei stări (quad 2-to-1 multiplexer with tri-state outputs)
                                        </li>
                                        <li>
                                            Face parte din familia 74LS (Low Power Schottky TTL).
                                        </li>
                                        <li>
                                            Număr de pini: 16.
                                        </li>
                                        <li>
                                            Tensiune de operare: 5V (TTL standard)
                                        </li>
                                        <li>
                                            4 multiplexoare independente, fiecare selectând între două intrări.
                                        </li>
                                        <li>
                                            Ieșiri cu trei stări (tri-state) pentru conectare la magistrale de date
                                        </li>
                                        <li>
                                            Selectare controlată printr-un singur pin pentru toate cele patru multiplexoare.
                                        </li>
                                        <li>
                                            Viteză mare datorită tehnologiei Schottky.
                                        </li>
                                    </ul>

                                    Pe placa e alt circuit <b>КР1533КП15</b>:
                                    <ul>
                                        <li>
                                            un circuit integrat fabricat în fosta URSS și Rusia, echivalent cu 74LS257A
                                        </li>
                                        <li>
                                            Multiplexor quad 2:1 cu ieșiri tri-state.
                                        </li>
                                        <li>
                                            Face parte din seria КР1533, care este echivalentă cu TTL 74LS (Low Power Schottky).
                                        </li>
                                        <li>
                                            Tensiune de operare: 5V (TTL standard).
                                        </li>
                                        <li>
                                            16 pini, la fel ca 74LS257A.
                                        </li>
                                        <li>
                                            Folosit pentru comutarea între două surse de date, având un singur semnal de selecție
                                        </li>

                                    </ul>

                                    Motive pentru înlocuire:
                                    <ul>
                                        <li>Disponibilitatea componentelor sovietice/rusești în anumite regiuni.</li>
                                        <li>
                                            Fiabilitate sau cost redus comparativ cu versiunile occidentale
                                        </li>
                                        <li>
                                            Reparații sau întreținere a plăcii.
                                        </li>
                                    </ul>

                                </li>
                                <li>
                                    TMM41464AP-12 (9037HCK), un circuit integrat fabricat de Toshiba:
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_32.png'}/>
                                    </div>
                                    <ul>
                                        <li>
                                            Este un circuit de memorie DRAM (Dynamic Random Access Memory) de 64K x 4 (256 kilobiți în total).
                                        </li>
                                        <li>
                                            Funcționează la 5V, fiind compatibil cu standardele TTL.
                                        </li>
                                        <li>
                                            Sufixul -12 indică un timp de acces de 120ns.
                                        </li>
                                        <li>
                                            Capacitate: 64K x 4 biți (256Kbit, adică 32KB de memorie).
                                        </li>
                                        <li>
                                            Tip memorie: DRAM, necesită reîmprospătare (refresh) periodic.
                                        </li>
                                        <li>
                                            Timp de acces: 120 ns.
                                        </li>
                                        <li>
                                            Tehnologie: NMOS.
                                        </li>
                                        <li>
                                            Pachet: DIP-18 (Dual Inline Package cu 18 pini).
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Circuit din familia <b>74LS</b> pentru logica digitala:
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_33.png'}/>
                                    </div>
                                    Variante posibile:
                                    <ul>
                                        <li>
                                            74LS00 (porți NAND),
                                        </li>
                                        <li>
                                            74LS02 (NOR gate)
                                        </li>
                                        <li>
                                            74LS04 (invertoare)
                                        </li>
                                        <li>
                                            74LS08 (quad AND gate)
                                        </li>
                                        <li>
                                            74LS32 (porți OR)
                                        </li>
                                        <li>
                                            74LS157 sau 74LS257 (multiplexoare)
                                        </li>
                                        <li>
                                            74LS138 (decodificator)
                                        </li>
                                        <li>
                                            74LS245, (transceiver bidirecțional de magistrală cu trei stări (tri-state))
                                        </li>
                                        <li>
                                            74LS17
                                            <ul>
                                                <li>
                                                    Conține 6 buffere Schmitt Trigger, fiecare cu ieșire open-collector.
                                                </li>
                                                <li>
                                                    Funcționează la 5V și este compatibil cu logica TTL.
                                                </li>
                                                <li>
                                                    Schmitt Trigger – Transformă semnale cu tranziții lente sau zgomotoase într-un semnal digital clar.
                                                </li>
                                                <li>
                                                    Ieșire open-collector – Necesită un rezistor pull-up pentru a furniza un semnal logic HIGH
                                                </li>
                                                <li>
                                                    Timp de propagare redus – Se încadrează în specificațiile 74LS, fiind rapid și eficient energetic.
                                                </li>
                                                <li>
                                                    6 canale independente, fiecare cu o singură intrare și o ieșire.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    GD74LS373
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_34.png'}/>
                                    </div>
                                    <ul>
                                        <li>
                                            Este un latch D octal (8 biți) cu ieșiri tri-state.
                                        </li>
                                        <li>
                                            Face parte din familia 74LS (Low Power Schottky TTL)
                                        </li>
                                        <li>
                                            Funcționează la 5V și este compatibil cu logica TTL.
                                        </li>
                                        <li>
                                            Pachet standard DIP-20 (Dual Inline Package cu 20 pini)
                                        </li>
                                        <li>
                                            8 biți de stocare temporară – Poate reține un octet de date.
                                        </li>
                                        <li>
                                            Ieșiri tri-state – Permite conectarea mai multor dispozitive la aceeași magistrală de date.
                                        </li>
                                        <li>
                                            Control printr-un semnal de latch enable (LE) – Datele sunt reținute cât timp LE este HIGH.
                                        </li>
                                        <li>
                                            Consum redus de putere datorită tehnologiei Schottky.
                                        </li>
                                        <li>
                                            Timp de propagare scurt (~20ns) pentru viteze mari.
                                        </li>
                                        <li>
                                            Interfațare cu memorie și periferice – Transfer de date între procesor și RAM/ROM.
                                        </li>
                                        <li>
                                            Latch de adresă în ZX Spectrum și HC-91 – Stochează temporar adrese pentru acces la memorie.
                                        </li>
                                    </ul>

                                </li>

                                <li>
                                    Circuitul T74LS374B1
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_35.png'}/>
                                    </div>
                                    <ul>
                                        <li>
                                            este un flip-flop octal D cu ieșiri tri-state, echivalent cu 74LS374, produs de SGS-Thomson (STMicroelectronics).
                                        </li>
                                        <li>
                                            Este un flip-flop D octal (8 biți) cu ieșiri tri-state.
                                        </li>
                                        <li>
                                            Face parte din familia 74LS (Low Power Schottky TTL).
                                        </li>
                                        <li>
                                            Funcționează la 5V și este compatibil cu logica TTL.
                                        </li>
                                        <li>
                                            Pachet standard DIP-20 (Dual Inline Package cu 20 pini).
                                        </li>
                                        <li>
                                            Se diferențiază de 74LS373 prin faptul că are edge-triggered flip-flops în loc de latch-uri.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Circuitul GD74LS166 9335 KOREA
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_36.png'}/>
                                    </div>
                                    <ul>
                                        <li>
                                            Circuitul GD74LS166 este un registru de deplasare (shift register) paralel-serial de 8 biți, echivalent cu 74LS166, produs de Goldstar
                                            (acum LG Electronics).
                                        </li>
                                        <li>
                                            Registru de deplasare paralel-serial de 8 biți.
                                        </li>
                                        <li>
                                            Face parte din familia 74LS (Low Power Schottky TTL).
                                        </li>
                                        <li>
                                            Funcționează la 5V, compatibil cu logica TTL.
                                        </li>
                                        <li>
                                            Pachet standard DIP-16 (Dual Inline Package cu 16 pini).
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Circuitul GD74LS257A 9241
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_37.png'}/>
                                    </div>

                                    <ul>
                                        <li>
                                            Circuitul GD74LS257A este un multiplexor quad 2:1 cu ieșiri tri-state, echivalent cu 74LS257A, fabricat de Goldstar (LG Electronics).
                                        </li>
                                        <li>
                                            Face parte din familia 74LS (Low Power Schottky TTL).
                                        </li>
                                        <li>
                                            Funcționează la 5V, compatibil cu logica TTL.
                                        </li>
                                        <li>
                                            Pachet standard DIP-16 (Dual Inline Package cu 16 pini).
                                        </li>
                                        <li>
                                            Permite selectarea dintre două seturi de date de 4 biți, direcționând unul dintre ele spre ieșiri.
                                        </li>
                                        <li>
                                            Selectarea sursei de date cu un singur pin (SELECT).
                                        </li>
                                        <li>
                                            Timp de propagare scurt (~10 ns), fiind rapid pentru aplicații digitale.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Circuitul SN74LS161AN
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-7/img_38.png'}/>
                                    </div>

                                    <ul>
                                        <li>
                                            Circuitul SN74LS161AN este un contor binar sincron de 4 biți, fabricat de Texas Instruments, și face parte din familia 74LS (Low Power Schottky TTL).
                                        </li>
                                        <li>
                                            Contor binar sincron de 4 biți cu încărcare paralelă.
                                        </li>
                                        <li>
                                            Funcționează la 5V, compatibil cu logica TTL.
                                        </li>
                                        <li>
                                            Pachet standard DIP-16 (Dual Inline Package cu 16 pini).
                                        </li>
                                        <li>
                                            Sincronizare pe frontul crescător al ceasului – Toate ieșirile se schimbă simultan la fiecare impuls de ceas.
                                        </li>
                                        <li>
                                            4 biți de numărare sincronizată (0-15)
                                        </li>
                                        <li>
                                            Intrare de încărcare paralelă – Permite setarea manuală a unei valori inițiale
                                        </li>
                                        <li>
                                            Intrare de resetare (CLEAR, activ LOW) – Resetează contorul la 0000
                                        </li>
                                        <li>
                                            Intrări de activare (ENP și ENT) – Controlează incrementarea contorului
                                        </li>
                                        <li>
                                            Ieșire de transport (RCO) – Permite cascadarea cu alte contoare
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </li>

                        <li>
                            Rezistențe și condensatori – Diverse componente pasive, inclusiv rezistențe axiale, condensatori ceramici (galbeni și albaștri), electrolitici (negri)
                            și un condensator verde, probabil cu tantal.
                            <br/>
                            Capacitator/Condensator 100nK63 (echivalent cu 100nF)
                            <br/>
                            <ul>
                                <li>Capacitatea: 100 nanofarazi (nF)=0.1 microfrazi (uF)</li>
                                <li>Tensiune de lucru: 63V</li>
                                <li>Toleranta: +-10%</li>
                            </ul>
                        </li>

                    </li>
                </ul>

            </div>

            <div className={"text-justify"}>

                Link-uri utile:
                <ul>
                    <li>
                        <a target={"_blank"} href={"https://www.electrokits.ro/familia-de-circuite-integrate-ttl-transistor-transistor-logic/"}>
                            Familia de circuite integrate TTL (Transistor Transistor Logic)
                        </a>
                    </li>
                </ul>

            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default HC128MuseumContent;