import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function FelixBuzunarMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-felix-buzunar";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>Felix CE 835A</b>
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>An: 1979</li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_1.png'}/>
                </div>


                <hr/>
                <b>Felix CE 120</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_12.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-7/img_13.png'}/>
                </div>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default FelixBuzunarMuseumContent;