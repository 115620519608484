function MuseumToc() {

    let content = [

        {id: "museum-root", url: "#", title: "Despre...", subtitle: "", defaultUrl: "/museum/index"},
        {id: "museum-storage", url: "/museum/storage", title: "Medii de stocare", subtitle: "", parent: "museum-root"},
        {id: "museum-others", url: "/museum/others", title: "Console de jocuri", subtitle: "", parent: "museum-root"},
        {id: "museum-cable", url: "/museum/cable", title: "Cabluri", subtitle: "", parent: "museum-root"},
        {id: "museum-joystick", url: "/museum/joystick", title: "Joystick", subtitle: "", parent: "museum-root"},
        {id: "museum-floppy", url: "/museum/floppy", title: "Floppy", subtitle: "", parent: "museum-root"},
        {id: "museum-monitor", url: "/museum/monitor", title: "Monitor", subtitle: "", parent: "museum-root"},
        {id: "museum-casetofon", url: "/museum/casetofon", title: "Casetofon", subtitle: "", parent: "museum-root"},
        {id: "museum-alimentator", url: "/museum/alimentator", title: "Alimentatoare", subtitle: "", parent: "museum-root"},
        {id: "museum-module", url: "/museum/module", title: "Module", subtitle: "", parent: "museum-root"},
        {id: "museum-keyboard", url: "/museum/keyboard", title: "Tastaturi", subtitle: "", parent: "museum-root"},

        {id: "museum-pc", url: "#", title: "Calculatoare romanesti", subtitle: ""},
        {id: "museum-cip", url: "/museum/cip", title: "CIP", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip01", url: "/museum/cip01", title: "=> CIP 01 (103176)", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip01_2", url: "/museum/cip01_2", title: "=> CIP 01 (nefunctional)", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip03-21", url: "/museum/cip03-21", title: "=> CIP 03 (215193 / rosu)", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip03-rosu", url: "/museum/cip03-rosu", title: "=> CIP 03 (rosu)", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip03-217498", url: "/museum/cip03-217498", title: "=> CIP 03 (217498 / albastru)", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip03-505299", url: "/museum/cip03-505299", title: "=> CIP 03 (505299 / albastru / nefunctional)", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip03-505408", url: "/museum/cip03-505408", title: "=> CIP 03 (505408 / albastru / nefunctional)", subtitle: "", parent: "museum-pc"},
        {id: "museum-cip03-200970", url: "/museum/cip03-200970", title: "=> CIP 03 (200970 / albastru / nefunctional)", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc", url: "/museum/hc", title: "HC", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc90", url: "/museum/hc90", title: "=> HC 90", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc90-835", url: "/museum/hc90-835", title: "=> HC 90 (835 / port alimentare)", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc91-24629", url: "/museum/hc91-24629", title: "=> HC 91 (24629) - 40 de taste", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc91", url: "/museum/hc91", title: "=> HC 91 (44187) - 50 de taste", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc91-912", url: "/museum/hc91-912", title: "=> HC 91 (912)", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc128", url: "/museum/hc128", title: "=> HC 128", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc2000", url: "/museum/hc2000", title: "=> HC 2000", subtitle: "", parent: "museum-pc"},
        {id: "museum-hc2000-5954", url: "/museum/hc2000-5954", title: "=> HC 2000 (5954, nefunctional)", subtitle: "", parent: "museum-pc"},
        {id: "museum-amic", url: "/museum/amic", title: "aMic", subtitle: "", parent: "museum-pc"},
        {id: "museum-jet", url: "/museum/jet", title: "JET", subtitle: "", parent: "museum-pc"},
        {id: "museum-cobra", url: "/museum/cobra", title: "CoBra", subtitle: "", parent: "museum-pc"},
        {id: "museum-cob2", url: "/museum/cob2", title: "=> CoBra (Cob2)", subtitle: "", parent: "museum-pc"},
        {id: "museum-daf2020c", url: "/museum/daf2020c", title: "DAF 2020C", subtitle: "", parent: "museum-pc"},

        {id: "museum-pcs", url: "#", title: "Calculatoare straine", subtitle: ""},
        {id: "museum-commodore", url: "/museum/commodore", title: "Commodore", subtitle: "", parent: "museum-pcs"},
        {id: "museum-spectrum", url: "/museum/spectrum", title: "Spectrum", subtitle: "", parent: "museum-pcs"},
        {id: "museum-byte", url: "/museum/byte", title: "Byte", subtitle: "", parent: "museum-pcs"},
        {id: "museum-delta", url: "/museum/delta", title: "Delta", subtitle: "", parent: "museum-pcs"},

        {id: "museum-buzunar", url: "#", title: "Calculatoare de buzunar", subtitle: ""},
        {id: "museum-felix-buzunar", url: "/museum/felix-buzunar", title: "Felix", subtitle: "", parent: "museum-buzunar"},

        {id: "museum-procesor-root", url: "#", title: "Componente", subtitle: ""},
        {id: "museum-procesor", url: "/museum/Procesor", title: "Procesoare", subtitle: "", parent: "museum-procesor-root"},

        {id: "museum-history-root", url: "#", title: "Istoric", subtitle: ""},
        {id: "museum-history", url: "/museum/history", title: "Istoria calculatoarelor romanesti", subtitle: "", parent: "museum-history-root"},
        {id: "books-it-old", url: "/books/it-old", title: "Carti", subtitle: "", parent: "museum-history-root"},

        {id: "museum-bancnote-root", url: "#", title: "Bancnote", subtitle: ""},
        {id: "museum-bancnote-ro", url: "/museum/bancnote", title: "Bancnote romanesti", subtitle: "", parent: "museum-bancnote-root"},

        {id: "museum-util-root", url: "#", title: "Util", subtitle: ""},
        {id: "museum-util-adapter", url: "/museum/util/adapter", title: "Adaptoare", subtitle: "", parent: "museum-util-root"},

    ]

    const getContent = () => {
        return content;
    }
    return {
        getContent
    }

}

export default MuseumToc;