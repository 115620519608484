import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../books/IndexContent";

class PhotoBooksContent extends BaseContentPage  {

    constructor(props) {
        super(props, "books-photo", IndexContent);

        this.state = {
            ...this.state,
            //  filtredBooks: [],
            search:''
        };
    }

    static books = [
        {
            id:1,
            title:"Film Noir Photography",
            publisher:"Crowood Press",
            year:2019,
            authors:["Neil Freeman"],
            pages:160,
            language: "engleza",
            ISBN: "178500607X",
            img:"film-noir-photography.jpg"
        },

        {
            id:2,
            title:"1000 Nudes. A History of Erotic Photography from 1839-1939",
            publisher:"Taschen GmbH",
            year:2021,
            authors:["Hans-Michael Koetzle"],
            pages:576,
            language: "engleza",
            ISBN: "3836554461",
            img:"02776603_1000-nudes-a-history-of-erotic-photography-from-1839-1939.jpg"
        },

        {
            id:3,
            title:"A History of Photography",
            publisher:"Taschen GmbH",
            year:2021,
            authors:["Jutta Hendricks"],
            pages:768,
            language: "engleza",
            ISBN: "3836540991",
            img:"00201965_a-history-of-photography.jpg"
        },

        {
            id:4,
            title:"A History of Photography",
            publisher:"Taschen GmbH",
            year:2017,
            authors:["Dian Hanson"],
            pages:592,
            language: "engleza",
            ISBN: "9783836526715",
            img:"181048375.jpeg"
        },

        {
            id:5,
            title:"Photographers A-Z",
            publisher:"Taschen GmbH",
            year:2017,
            authors:["Hans-Michael Koetzle"],
            pages:640,
            language: "engleza",
            ISBN: "9783836554367",
            img:"9783836554367-2872406.jpg"
        },

        {
            id:6,
            title:"Eduardo Momene: PHotoBolsillo",
            publisher:"La Fabrica",
            year:2017,
            authors:["Eduardo Momene"],
            pages:96,
            language: "engleza",
            ISBN: "8416248958",
            img:"15872651_eduardo-momene-photobolsillo.jpg"
        },

        {
            id:7,
            title:"What Makes Great Photography",
            publisher:"Frances Lincoln Publishers Ltd",
            year:2013,
            authors:["Val Williams"],
            pages:224,
            language: "engleza",
            ISBN: "0711235066",
            img:"01331299_what-makes-great-photography.jpg"
        },

        {
            id:8,
            title:"Isms: Understanding Photography",
            publisher:"Taylor & Francis Ltd",
            year:2017,
            authors:["Emma Lewis"],
            pages:168,
            language: "engleza",
            ISBN: "9781474277594",
            img:"14489007_isms-understanding-photography.jpg"
        },

        {
            id:9,
            title:"Paris Brassai",
            publisher:"Editions Flammarion",
            year:2017,
            authors:["Brassai"],
            pages:158,
            language: "engleza",
            ISBN: "9782080203144",
            img:"15757750_paris-brassai.jpg"
        },

        {
            id:10,
            title:"Albert Watson: Creating Photographs",
            publisher:"Laurence King",
            year:2021,
            authors:["Albert Watson"],
            pages:128,
            language: "engleza",
            ISBN: "9781786278838",
            img:"albert-watson.jpg"
        },

        {
            id:11,
            title:"Women Photographers: Pioneers 1851-1936",
            publisher:"Thames & Hudson Ltd",
            year:2020,
            authors:["-"],
            pages:144,
            language: "engleza",
            ISBN: "0500411158",
            img:"32951928o.jpg"
        },

        {
            id:12,
            title:"Women Photographers: Revolutionaries 1937-1970",
            publisher:"Thames & Hudson Ltd",
            year:2020,
            authors:["-"],
            pages:144,
            language: "engleza",
            ISBN: "0500411166",
            img:"32951929o.jpg"
        },

        {
            id:13,
            title:"Women Photographers: Contemporaries 1970-today",
            publisher:"Thames & Hudson Ltd",
            year:2020,
            authors:["-"],
            pages:144,
            language: "engleza",
            ISBN: "0500411174",
            img:"32951930o.jpg"
        },

        {
            id:14,
            title:"Little Book of Pussy",
            publisher:"Taschen GmbH",
            year:2014,
            authors:["Dian Hanson"],
            pages:192,
            language: "engleza",
            ISBN: "9783836545174",
            img:"01283672_little-book-of-pussy.jpg"
        },

        {
            id:15,
            title:"Pussy Book",
            publisher:"Taschen GmbH",
            year:2018,
            authors:["Dian Hanson"],
            pages:512,
            language: "engleza",
            ISBN: "9783836566858",
            img:"18618656_dian-hansons-pussy-book.jpg"
        },

        {
            id:16,
            title:"What They Didn't Teach You in Photo School",
            publisher:"Octopus Publishing Group",
            year:2015,
            authors:["Demetrius Fordham"],
            pages:208,
            language: "engleza",
            ISBN: "1781572690",
            img:"09186846_what-they-didn-t-teach-you-in-photo-school.jpg"
        },

        {
            id:17,
            title:"The Male Nude",
            publisher:"Taschen GmbH",
            year:2015,
            authors:["David Leddick"],
            pages:576,
            language: "engleza",
            ISBN: "3836558017",
            img:"00195196_the-male-nude.jpg"
        },

        {
            id:18,
            title:"Araki: Tokyo Lucky Hole",
            publisher:"Taschen GmbH",
            year:2016,
            authors:["Nobuyoshi Araki"],
            pages:704,
            language: "engleza",
            ISBN: "3836556383",
            img:"09096543_araki-tokyo-lucky-hole.jpg"
        },

        {
            id:19,
            title:"Alfred Stieglitz. Camera Work",
            publisher:"Taschen GmbH",
            year:2018,
            authors:["Pam Roberts"],
            pages:552,
            language: "engleza",
            ISBN: "3836544075",
            img:"01256967_camera-work.jpg"
        },

        {
            id:20,
            title:"CCCP",
            publisher:"Taschen GmbH",
            year:2019,
            authors:["Frédéric Chaubin"],
            pages:448,
            language: "engleza",
            ISBN: "9783836565059",
            img:"15733331_frederic-chaubin-cccp.jpg"
        },

        {
            id:21,
            title:"Lewis W. Hine. America at Work",
            publisher:"Taschen GmbH",
            year:2018,
            authors:["Peter Walther"],
            pages:544,
            language: "engleza",
            ISBN: "9783836572347",
            img:"19684849_lewis-w-hine-america-at-work.jpg"
        },

        {
            id:22,
            title:"20th Century Photography",
            publisher:"Taschen GmbH",
            year:2018,
            authors:["Jutta Hendricks"],
            pages:760,
            language: "engleza",
            ISBN: "3836541025",
            img:"01233893_20th-century-photography.jpg"
        },

        {
            id:23,
            title:"Guy Bourdin",
            publisher:"Thames & Hudson Ltd",
            year:2008,
            authors:["Gilles de Bure"],
            pages:144,
            language: "engleza",
            ISBN: "0500410909",
            img:"314k+UQS5kL._SX328_BO1,204,203,200_.jpg"
        },

        {
            id:24,
            title:"Paolo Roversi",
            publisher:"Thames & Hudson Ltd",
            year:2011,
            authors:["Laurence Benim","Gilles de Bure"],
            pages:144,
            language: "engleza",
            ISBN: "0500411042",
            img:"paolo-roversi.jpg"
        },


        {
            id:25,
            title:"Helmut Newton",
            publisher:"Thames & Hudson Ltd",
            year:2017,
            authors:["Helmut Newton"],
            pages:144,
            language: "engleza",
            ISBN: "0500410690",
            img:"helmut-newton.jpg"
        },

        {
            id:26,
            title:"Sebastiao Salgado",
            publisher:"Thames & Hudson Ltd",
            year:2016,
            authors:["Sebastiao Salgado"],
            pages:144,
            language: "engleza",
            ISBN: "978-0500410868",
            img:"12_2400x.jpg"
        },

        {
            id:27,
            title:"Richard Kalvar",
            publisher:"Thames & Hudson Ltd",
            year:2019,
            authors:["Richard Kalvar"],
            pages:144,
            language: "engleza",
            ISBN: "9780500411148",
            img:"251145349-0-240.jpeg"
        },

        {
            id:28,
            title:"Araki",
            publisher:"Thames & Hudson Ltd",
            year:2007,
            authors:["Araki"],
            pages:144,
            language: "engleza",
            ISBN: "0500410917",
            img:"araki.jpg"
        },

        {
            id:29,
            title:"Helmut Newton: Private Property",
            publisher:"Schirmer /Mosel Verlag Gm",
            year:2003,
            authors:["Helmut Newton"],
            pages:112,
            language: "engleza",
            ISBN: "978-3-88814-340-3",
            img:"private-property.jpg"
        },

        {
            id:30,
            title:"David Bailey: Bailey Exposed",
            publisher:"National Portrait Gallery Publications",
            year:2014,
            authors:["David Bailey"],
            pages:160,
            language: "engleza",
            ISBN: "9781855144668",
            img:"61+hBLPxE-L.jpg"
        },

        {
            id:31,
            title:"Photographers on Photography: How the Masters See, Think and Shoot",
            publisher:"Laurence King Publishing",
            year:2019,
            authors:["Henry Carroll"],
            pages:128,
            language: "engleza",
            ISBN: "9781786273185",
            img:"176126706-0-240.gif"
        },

        {
            id:32,
            title:"Why It Does Not Have to be In Focus: Modern Photography Explained",
            publisher:"Prestel ",
            year:2013,
            authors:["Jackie Higgins"],
            pages:224,
            language: "engleza",
            ISBN: "3791348515",
            img:"51tDFRJndcL._SX350_BO1,204,203,200_.jpg"
        },

        {
            id:33,
            title:"Mastering the Nikon D810",
            publisher:"Rocky Nook",
            year:2014,
            authors:["Rocky Nook"],
            pages:608,
            language: "engleza",
            ISBN: "1937538605",
            img:"05160612_mastering-the-nikon-d810.jpg"
        },

        {
            id:34,
            title:"A Different Light",
            publisher:"Duke University Press",
            year:2012,
            authors:["Parvati Nair"],
            pages:376,
            language: "engleza",
            ISBN: "0822350483",
            img:"01209388_different-light.jpg"
        },

        {
            id:35,
            title:"Group f.64",
            publisher:"Bloomsbury Publishing USA",
            year:2016,
            authors:["Mary Street Alinder"],
            pages:416,
            language: "engleza",
            ISBN: "9781620405567",
            img:"05366708_group-f-64.jpg"
        },

        {
            id:36,
            title:"Imaginea digiala",
            publisher:"Ad Libri",
            year:2004,
            authors:["Mark Galer","Les Horvat"],
            pages:256,
            language: "romana",
            ISBN: "9781620405567",
            img:"1-1216b9664f.jpg"
        },

        {
            id:37,
            title:"Adobe Photoshop CS6. Curs oficial Adobe",
            publisher:"Teora",
            year:2012,
            authors:["Mark Johann","Jay Graham"],
            pages:384,
            language: "romana",
            ISBN: "9789732013373",
            img:"9789732013373-2094124.jpg"
        },

        {
            id:38,
            title:"The Photographer's Handbook",
            publisher:"Knopf",
            year:1992,
            authors:["John Hedgecoe"],
            pages:352,
            language: "engleza",
            ISBN: "978-0679742043",
            img:"51YbH4fKTXL._SX349_BO1,204,203,200_.jpg"
        },

        {
            id:39,
            title:"Dancers in Motion: The Art and Technique of Dance Photography",
            publisher:"Amherst Media",
            year:2017,
            authors:["Susan Michal"],
            pages:128,
            language: "engleza",
            ISBN: "1682032043",
            img:"13813852_dancers-in-motion-the-art-and-technique-of-photographing-dancers.jpg"
        },

        {
            id:40,
            title:"Noi suntem Invictus",
            publisher:"-",
            year:2017,
            authors:["Ioana Moldovan"],
            pages:152,
            language: "romana",
            ISBN: "9789730257533",
            img:"225621111-1-240.jpeg"
        },

        {
            id:41,
            title:"Low-Light and Night PhotographyȘ Art and Techniques",
            publisher:"The Crowood Press Ltd",
            year:2017,
            authors:["Neil Freeman"],
            pages:160,
            language: "engleza",
            ISBN: "9781785002342",
            img:"25674038-0-240.jpeg"
        },

        {
            id:42,
            title:"The Forties in Pictures",
            publisher:"Parragon ",
            year:2008,
            authors:["James Lescott"],
            pages:256,
            language: "engleza",
            ISBN: "1405495219",
            img:"51RDrD84alL._SX402_BO1,204,203,200_.jpg"
        },

        {
            id:43,
            title:"Tara trista, plina de umor",
            publisher:"",
            year:2019,
            authors:["Nicolae Triculescu"],
            pages:121,
            language: "romana",
            ISBN: "9789730286182",
            img:"251274457-0.jpeg"
        },

        {
            id:44,
            title:"The Fashion Photography CourseȘ First Principles to Successful Shoot - the Essential Guide",
            publisher:"Thames & Hudson Ltd",
            year:2008,
            authors:["Eliot L. Siegel"],
            pages:144,
            language: "engleza",
            ISBN: "9780500287699",
            img:"9780500287699-1539249.jpg"
        },

        {
            id:45,
            title:"The White Shirt",
            publisher:"-",
            year:2018,
            authors:["Stefan Neagu"],
            pages:84,
            language: "romana",
            ISBN: "978-973-0-26437-1",
            img:"The-White-Shirt-coperta-fata.jpg"
        },

        {
            id:46,
            title:"Imagine Magazine Vol. IV / 2020",
            publisher:"QLT Studio 2",
            year:2020,
            authors:["-"],
            pages:300,
            language: "engleza",
            ISBN: "2734-5718",
            img:"imagine-iv.jpg"
        },

        {
            id:47,
            title:"Imagine Magazine Vol. V / 2021",
            publisher:"QLT Studio 2",
            year:2021,
            authors:["-"],
            pages:315,
            language: "engleza",
            ISBN: "2734-5718",
            img:"imagine-v.png"
        },

        {
            id:48,
            title:"Imagine Magazine Vol. VI / 2021",
            publisher:"QLT Studio 2",
            year:2021,
            authors:["-"],
            pages:299,
            language: "engleza",
            ISBN: "-",
            img:"imagine-vi.png"
        },

        {
            id:49,
            title:"Imagine Magazine Vol. III / 2020",
            publisher:"QLT Studio 2",
            year:2020,
            authors:["-"],
            pages:0,
            language: "engleza",
            ISBN: "2734-5718",
            img:"imagine-iii.png"
        },

        {
            id:50,
            title:"Photography Masterclass: Creative Techniques of 100 Great Photographers",
            publisher:"Thames & Hudson",
            year:2016,
            authors:["Paul Lowe"],
            pages:288,
            language: "engleza",
            ISBN: "050054462X",
            img:"01016517_photography-masterclass-creative-techniques-of-100-great-photographers.jpg"
        },

        {
            id:51,
            title:"35 Awards - Catalogue 2018 (4th International Photography Awards)",
            publisher:"35 Awards",
            year:2019,
            authors:["-"],
            pages:512,
            language: "engleza",
            ISBN: "",
            img:"15972.jpg"
        },

        {
            id:52,
            title:"Pinhole Photography",
            publisher:"Taylor & Francis Ltd",
            year:2008,
            authors:["Eric Renner"],
            pages:260,
            language: "engleza",
            ISBN: "0240810473",
            img:"04043624_pinhole-photography.jpg"
        },

        {
            id:53,
            title:"Mario Testino. Undressed",
            publisher:"Taschen GmbH",
            year:2017,
            authors:["Mario Testino"],
            pages:144,
            language: "engleza",
            ISBN: "383656646X",
            img:"719TRCHE5bL.jpg"
        },

        {
            id:54,
            title:"MaRIO DE JANEIRO Testino",
            publisher:"Taschen GmbH",
            year:2009,
            authors:["Mario Testino"],
            pages:200,
            language: "engleza",
            ISBN: "3836518589",
            img:"04336404_mario-testino-rio-de-janeiro.jpg"
        },

        {
            id:55,
            title:"Steve McCurry Untold: The Stories Behind the Photographs",
            publisher:"Phaidon Press Ltd",
            year:2018,
            authors:["Steve McCurry"],
            pages:304,
            language: "engleza",
            ISBN: "9780714877341",
            img:"19428090_steve-mccurry-untold.jpg"
        },

        {
            id:56,
            title:"Francesca Woodman: On Being an Angel",
            publisher:"Koenig Books",
            year:2018,
            authors:["Francesca Woodman"],
            pages:232,
            language: "engleza",
            ISBN: "9783863357504",
            img:"francesca-woodman.jpg"
        },

        {
            id:57,
            title:"Shooting Film: Everything you need to know about analogue photography",
            publisher:"Octopus Publishing Group",
            year:2022,
            authors:["Ben Hawkins","Liza Kanaeva-Hunsicker"],
            pages:192,
            language: "engleza",
            ISBN: "9781781578346",
            img:"1522632748-0-240.jpeg"
        },

        {
            id:58,
            title:"How to Set Up Photography Lighting for a Home Studio",
            publisher:"Createspace Independent Publishing Platform",
            year:2014,
            authors:["Amber Richards"],
            pages:94,
            language: "engleza",
            ISBN: "1503003876",
            img:"17353686_how-to-set-up-photography-lighting-for-a-home-studio.jpg"
        },


        {
            id:59,
            title:"Photographing Women",
            publisher:"ROCKY NOOK",
            year:2017,
            authors:["Jeff Rojas"],
            pages:248,
            language: "engleza",
            ISBN: "1681981742",
            img:"12447697_photographing-women-posing-lighting-and-shooting-techniques-for-portrait-and-fashion-photography.jpg"
        },

        {
            id:60,
            title:"Portrait Photography",
            publisher:"The Crowood Press Ltd",
            year:2013,
            authors:["Saraya Cortaville"],
            pages:144,
            language: "engleza",
            ISBN: "1847975119",
            img:"41BqMZ5BUtS._SX380_BO1,204,203,200_.jpg"
        },

        {
            id:61,
            title:"Light It, Shoot It, Retouch It",
            publisher:"New Riders",
            year:2012,
            authors:["Scott Kelby"],
            pages:264,
            language: "engleza",
            ISBN: "0321786610",
            img:"04409251_light-it-shoot-it-retouch-it.jpg"
        },

        {
            id:62,
            title:"50 Photographers You Should Know",
            publisher:"Prestel",
            year:2017,
            authors:["Peter Stepan"],
            pages:160,
            language: "engleza",
            ISBN: "9783791383590",
            img:"16293253_50-photographers-you-should-know.jpg"
        },

        {
            id:63,
            title:"50 Contemporary Photographers You Should Know",
            publisher:"Prestel",
            year:2016,
            authors:["Florian Heine","Brad Finger"],
            pages:160,
            language: "engleza",
            ISBN: "9783791382593",
            img:"10929869_50-contemporary-photographers-you-should-know.jpg"
        },

        {
            id:64,
            title:"Selected Works",
            publisher:"teNeues Publishing UK Ltd",
            year:2019,
            authors:["Vincent Peters"],
            pages:160,
            language: "engleza",
            ISBN: "396171228X",
            img:"22297634_selected-works.jpg"
        },

        {
            id:65,
            title:"Adobe Photoshop Lightroom CC / Lightroom 6 Book",
            publisher:"Pearson Education",
            year:2015,
            authors:["Martin Evening"],
            pages:744,
            language: "engleza",
            ISBN: "0133929191",
            img:"09291892_adobe-photoshop-lightroom-cc-lightroom-6-book.jpg"
        },

        {
            id:66,
            title:"Adobe Photoshop CC Classroom in a Book (2017 Release)",
            publisher:"Pearson Education",
            year:2016,
            authors:["Andrew Faulkner","Conrad Chavez"],
            pages:400,
            language: "engleza",
            ISBN: "0134663454",
            img:"15470736_adobe-photoshop-cc-classroom-in-a-book-2017-release.jpg"
        },

        {
            id:67,
            title:"Magnum Streetwise",
            publisher:"Thames & Hudson Ltd",
            year:2019,
            authors:["Stephen Mclaren"],
            pages:382,
            language: "engleza",
            ISBN: "0500545073",
            img:"21364294_magnum-streetwise.jpg"
        },

        {
            id:68,
            title:"35 Awards - Catalogue 2018 (5th International Photography Awards)",
            publisher:"35 Awards",
            year:2020,
            authors:["-"],
            pages:512,
            language: "engleza",
            ISBN: "",
            img:"35-awards-5.png"
        },

        {
            id:69,
            title:"Helmut Newton. Work",
            publisher:"Taschen GmbH",
            year:2018,
            authors:["Helmut Newton"],
            pages:280,
            language: "engleza",
            ISBN: "9783836574228",
            img:"19539744_helmut-newton-work.jpg"
        },

        {
            id:70,
            title:"Francesca Woodman. Portrait of a reputation",
            publisher:"Mondadori Electa",
            year:2019,
            authors:["Nora Burnett Abrams","George Lange","Drew Sawyer"],
            pages:176,
            language: "engleza",
            ISBN: "084786491X",
            img:"22265444_francesca-woodman-portrait-of-a-reputation.jpg"
        },

        {
            id:71,
            title:"Francesca Woodmann",
            publisher:"Phaidon Press Ltd",
            year:2016,
            authors:["Chris Townsend"],
            pages:256,
            language: "engleza",
            ISBN: "9780714873183",
            img:"9780714873183.jpg"
        },

        {
            id:72,
            title:"Peter Lindbergh: Images of Women",
            publisher:"Schirmer/Mosel",
            year:2013,
            authors:["Martin Harrison"],
            pages:312,
            language: "engleza",
            ISBN: "9783829606370",
            img:"res_fd830b81101361e65a2b61e53171d407.jpg"
        },

        {
            id:73,
            title:"Peter Lindbergh. On Fashion Photographyn",
            publisher:"Taschen GmbH",
            year:2020,
            authors:["Peter Lindbergh"],
            pages:440,
            language: "engleza",
            ISBN: "3836584425",
            img:"28237609_peter-lindbergh-on-fashion-photography.jpg"
        },

        {
            id:74,
            title:"Anton Corbijn. The Living and the Dead",
            publisher:"Schirmer/Mosel",
            year:2019,
            authors:["Franz-W. Kaiser","Anton Corbijn"],
            pages:160,
            language: "engleza",
            ISBN: "9783829608343",
            img:"40961747._SX318_.jpg"
        },

        {
            id:75,
            title:"Mastering Portrait Photography",
            publisher:"Ammonite Press",
            year:2015,
            authors:["Sarah Plater","Paul Wilkinson"],
            pages:176,
            language: "engleza",
            ISBN: "1781450854",
            img:"mastering-portrait-photography.jpg"
        },

        {
            id:76,
            title:"Imagine Magazine Vol. VII / 2022",
            publisher:"QLT Studio 2",
            year:2020,
            authors:["-"],
            pages:344,
            language: "engleza",
            ISBN: "-",
            img:"imagine-vii.png"
        },


        {
            id:77,
            title:"Jo Ann Callis. Other Rooms",
            publisher:"Aperture",
            year:2014,
            authors:["Jo Ann Callis","Francine Prose"],
            pages:88,
            language: "engleza",
            ISBN: "1597112755",
            img:"05088290_other-rooms.jpg"
        },

        {
            id:78,
            title:"Bruce Davidson",
            publisher:"Prestel",
            year:2016,
            authors:["Vicki Goldberg"],
            pages:192,
            language: "engleza",
            ISBN: "3791381350",
            img:"02865952_bruce-davidson-magnum-legacy.jpg"
        },

        {
            id:79,
            title:"Eve Arnold",
            publisher:"Prestel",
            year:2015,
            authors:["Janine Giovanni"],
            pages:192,
            language: "engleza",
            ISBN: "3791349635",
            img:"09035645_eve-arnold.jpg"
        },

        {
            id:80,
            title:"Photojournalism 1855 to the Present",
            publisher:"Abbeville Press Inc.,U.S.",
            year:2015,
            authors:["Reuel Golden"],
            pages:256,
            language: "engleza",
            ISBN: "9780789208958",
            img:"05352131_photojournalism-1855-to-the-present.jpg"
        },

        {
            id:81,
            title:"Photography: History. Art. Technique",
            publisher:"Dorling Kindersley Ltd",
            year:2019,
            authors:["Tom Ang"],
            pages:336,
            language: "engleza",
            ISBN: "9780241363607",
            img:"301205548-0.jpeg"
        },

        {
            id:82,
            title:"True Confessions of Nude Photography",
            publisher:"Bella Nuda Arts",
            year:2017,
            authors:["A K Nicholas"],
            pages:130,
            language: "engleza",
            ISBN: "0985026480",
            img:"15861007_true-confessions-of-nude-photography.jpg"
        },

        {
            id:83,
            title:"Clear Nude: The lens and the nude",
            publisher:"Clear Nude Publications",
            year:2014,
            authors:["Allicette Torres"],
            pages:74,
            language: "engleza",
            ISBN: "061595278X",
            img:"16847427_clear-nude-the-lens-and-the-nude.jpg"
        },

        {
            id:84,
            title:"Open legs: Erotic photography and daring nudes",
            publisher:"Createspace Independent Publishing Platform",
            year:2016,
            authors:["Markus Bauer"],
            pages:98,
            language: "engleza",
            ISBN: "1534659781",
            img:"17685745_open-legs-erotic-photography-and-daring-nudes.jpg"
        },

        {
            id:85,
            title:"black & white: nude and erotic photography",
            publisher:"Createspace Independent Publishing Platform",
            year:2016,
            authors:["Markus Bauer"],
            pages:98,
            language: "engleza",
            ISBN: "1530709105",
            img:"17630525_black-amp-white-nude-and-erotic-photography.jpg"
        },

        {
            id:86,
            title:"Seraph",
            publisher:"BABY TATTOO BOOKS",
            year:2018,
            authors:["Allan Amato"],
            pages:160,
            language: "engleza",
            ISBN: "1614040206",
            img:"19271726_seraph.jpg"
        },

        {
            id:87,
            title:"Women Photographers",
            publisher:"Pretel",
            year:2018,
            authors:["Boris Friedewald"],
            pages:240,
            language: "engleza",
            ISBN: "9783791384665",
            img:"19647189_women-photographers.jpg"
        },

        {
            id:88,
            title:"The Digital Female Nude",
            publisher:"GMC Publications",
            year:2010,
            authors:["Peter Adams"],
            pages:176,
            language: "engleza",
            ISBN: "1861085567",
            img:"04306282_digital-female-nude.jpg"
        },

        {
            id:89,
            title:"Photographing Models: 1,000 Poses",
            publisher:"Bloomsbury Publishing",
            year:2012,
            authors:["Eliot Siegel"],
            pages:329,
            language: "engleza",
            ISBN: "1408170914",
            img:"01214156_photographing-models-1-000-poses.jpg"
        },

        {
            id:90,
            title:"Lighting the Nude, Revised Edition: Top Photographers Reveal Their Secrets",
            publisher:"Amphoto Books",
            year:2010,
            authors:["Roger Hicks","Frances Schultz","Steve Luck"],
            pages:384,
            language: "engleza",
            ISBN: "978-0-8174-0006-4",
            img:"41eu9kwGURL._SX355_BO1,204,203,200_.jpg"
        },

        {
            id:91,
            title:"Private Album",
            publisher:"Carlton Publishing",
            year:2004,
            authors:["Tom Ang","Wendy Ang"],
            pages:128,
            language: "engleza",
            ISBN: "184442927X",
            img:"04857255_private-album.jpg"
        },

        {
            id:92,
            title:"Fine Art Nudes",
            publisher:"Amherst Media",
            year:2015,
            authors:["Stan Trampe"],
            pages:128,
            language: "engleza",
            ISBN: "9781608959013",
            img:"03194098_fine-art-nudes.jpg"
        },

        {
            id:93,
            title:"Madonna: Nudes +",
            publisher:"ACC Art Books",
            year:2018,
            authors:["Martin H.M. Schreiber"],
            pages:80,
            language: "engleza",
            ISBN: "9781851498499",
            img:"14493597_madonna.jpg"
        },

        {
            id:94,
            title:"How to Read a Photograph",
            publisher:"Thames & Hudson Ltd",
            year:2019,
            authors:["Ian Jeffrey"],
            pages:448,
            language: "engleza",
            ISBN: "0500295387",
            img:"21328047o.jpg"
        },

        {
            id:95,
            title:"Thames & Hudson Dictionary of Photography",
            publisher:"Thames & Hudson Ltd",
            year:2018,
            authors:["Nathalie Herschdorfer"],
            pages:438,
            language: "engleza",
            ISBN: "9780500544990",
            img:"18186441_thames-hudson-dictionary-of-photography.jpg"
        },

        {
            id:96,
            title:"Helmut Newton, Sex and Landscapes",
            publisher:"Taschen GmbH",
            year:2004,
            authors:["Helmut Newton"],
            pages:112,
            language: "engleza",
            ISBN: "3822835064",
            img:"91hnT-N9tHL.jpg"
        },

        {
            id:97,
            title:"David Lynch, Nudes",
            publisher:"FONDAT CARTIER",
            year:2017,
            authors:["David Lynch"],
            pages:112,
            language: "engleza",
            ISBN: "9782869251342",
            img:"34910739o.jpg"
        },

        {
            id:98,
            title:"Sebastiao Salgado. Exodus",
            publisher:"Taschen GmbH",
            year:2015,
            authors:["Sebastiao Salgado"],
            pages:432,
            language: "engleza",
            ISBN: "3836561301",
            img:"02971720_sebastiao-salgado-exodus.jpg"
        },

        {
            id:99,
            title:"Ralph Gibson. Nude",
            publisher:"Taschen GmbH",
            year:2018,
            authors:["Ralph Gibson","Eric Fischl"],
            pages:353,
            language: "engleza",
            ISBN: "9783836568883",
            img:"18618657_ralph-gibson-nude.jpg"
        },

        {
            id:100,
            title:"Horst: Photographer of Style",
            publisher:"V&A",
            year:2014,
            authors:["Susanna Brown"],
            pages:352,
            language: "engleza",
            ISBN: "1851778012",
            img:"31JHy3nAw1L._SX385_BO1,204,203,200_.jpg"
        },

        {
            id:101,
            title:"50 photo icons. The story behind the pictures",
            publisher:"Taschen GmbH",
            year:2017,
            authors:["Hans-Michael Koetzle"],
            pages:307,
            language: "engleza",
            ISBN: "978-3836526937",
            img:"36745835_50-photo-icons-the-story-behind-the-pictures.jpg"
        },

        {
            id:102,
            title:"The Photography Book",
            publisher:"Phaidon",
            year:2014,
            authors:["Ian Jeffrey"],
            pages:576,
            language: "engleza",
            ISBN: "9780714867380",
            img:"the-photography-book.jpg"
        },

        {
            id:103,
            title:"Helmut Newton. Polaroids",
            publisher:"Taschen GmbH",
            year:2011,
            authors:["Helmut Newton"],
            pages:223,
            language: "engleza",
            ISBN: "383652886X",
            img:"04389754_helmut-newton-polaroids.jpg"
        },

        {
            id:104,
            title:"Helmut Newton. SUMO",
            publisher:"Taschen GmbH",
            year:2009,
            authors:["Helmut Newton"],
            pages:464,
            language: "engleza",
            ISBN: "3836517302",
            img:"41e8318e9zL._SX368_BO1,204,203,200_.jpg"
        },

        {
            id:105,
            title:"Helmut Newton Portraits: Photographs from Europe and America",
            publisher:"Schirmer/Mosel",
            year:2004,
            authors:["Helmut Newton"],
            pages:247,
            language: "engleza",
            ISBN: "9783829601313",
            img:"helmut-newton-portraits.jpg"
        },


        {
            id:106,
            title:"Helmut Newton and Alice Springs. Us and Them",
            publisher:"Taschen GmbH",
            year:2016,
            authors:["Florian Kobler"],
            pages:200 ,
            language: "engleza",
            ISBN: "978-3-8365-2467-4",
            img:"02890836_helmut-newton-and-alice-springs-us-and-them.jpg"
        },

        {
            id:107,
            title:"Man Ray",
            publisher:"Taschen GmbH",
            year:2017,
            authors:["Man Ray"],
            pages:252 ,
            language: "engleza",
            ISBN: "3836564491",
            img:"15799988_man-ray.jpg"
        },

        {
            id:108,
            title:"Paul Outerbridge",
            publisher:"Taschen GmbH",
            year:2017,
            authors:["Paul Outerbridge"],
            pages:256 ,
            language: "engleza",
            ISBN: "3836564564",
            img:"15691287_paul-outerbridge.jpg"
        },

        {
            id:109,
            title:"Edward Weston",
            publisher:"Taschen GmbH",
            year:2017,
            authors:["Edward Weston"],
            pages:252 ,
            language: "engleza",
            ISBN: "3836564505",
            img:"15691285_edward-weston.jpg"
        },

        {
            id:110,
            title:"1001 Photographs",
            publisher:"Octopus Publishing Grou",
            year:2017,
            authors:["Paul Lowe"],
            pages:960 ,
            language: "engleza",
            ISBN: "184403917X",
            img:"15850480_1001-photographs-you-must-see-before-you-die.jpg"
        },

        {
            id:111,
            title:"101 Nudes",
            publisher:"Blurb",
            year:2020,
            authors:["Carney Malone"],
            pages:110 ,
            language: "engleza",
            ISBN: "1320835228",
            img:"12030768_101-nudes.jpg"
        },

        {
            id:112,
            title:"Fotografia digitala cu aparate foto DSRL",
            publisher:"Casa",
            year:2010,
            authors:["Enczi Zoltan"],
            pages:192 ,
            language: "romana",
            ISBN: "978-606-8189-87-1",
            img:"64dbc3994bb02397775cef12bee9e399.jpg"
        },

        {
            id:113,
            title:"Fotografia digitala cu aparate foto DSRL",
            publisher:"Casa",
            year: 2016,
            authors:["Bill Hurter"],
            pages:192 ,
            language: "romana",
            ISBN: "978-606-787-014-5",
            img:"2206090_big.jpg"
        },

        {
            id:114,
            title:"Fotografia urbana si de peisaj cu aparatul foto digital",
            publisher:"Casa",
            year: 2011,
            authors:["Enczi Zoltan","Richard Keating"],
            pages:164 ,
            language: "romana",
            ISBN: "978-606-8189-28-4",
            img:"fotografia-urbana.jpg"
        },

        {
            id:115,
            title:"Fotografia de calatorie",
            publisher:"Casa",
            year: 2011,
            authors:["Enczi Zoltan","Richard Keating"],
            pages:144 ,
            language: "romana",
            ISBN: "978-606-8189-31-4",
            img:"af83072de1a25ef3fdf5b5b78b0a7821.jpg"
        },

        {
            id:116,
            title:"Curious Moments",
            publisher:"Könemann",
            year: 2018,
            authors:["Neubauer Hendrik"],
            pages:720 ,
            language: "engleza",
            ISBN: "9783833143533",
            img:"25296035_curious-moments.jpg"
        },

        {
            id:117,
            title:"Ghid complet Fotografia de Portret pe intelesul tuturor",
            publisher:"3D Media Communications",
            year: 2015,
            authors:["-"],
            pages:196 ,
            language: "romana",
            ISBN: "978-973-0-19066-3",
            img:"300x300.jpg"
        },

        {
            id:118,
            title:"Ghid pentru imagini creative Fotografia de Calatorie pe intelesul tuturor",
            publisher:"3D Media Communications",
            year: 2012,
            authors:["-"],
            pages:194 ,
            language: "romana",
            ISBN: "1453-7079",
            img:"res_23d585c57b39f05cc0477180f7e574c3.jpg"
        },

        {
            id:119,
            title:"100 Ideas that Changed Photography",
            publisher:"Orion Publishing Co",
            year: 2020,
            authors:["Mary Warner Marien"],
            pages:216 ,
            language: "engleza",
            ISBN: "1786275686",
            img:"22583243_100-ideas-that-changed-photography.jpg"
        },

        {
            id:120,
            title:"Build Your Own Pinhole Cameras",
            publisher:"Ilex",
            year: 2013,
            authors:["Justin Quinnell"],
            pages:96 ,
            language: "engleza",
            ISBN: "178157992X",
            img:"01283099_build-your-own-pinhole-cameras.jpg"
        },

        {
            id:121,
            title:"Pinhole Camera",
            publisher:"Princeton Architectural Press",
            year: 2011,
            authors:["Chris Keeney"],
            pages:192 ,
            language: "engleza",
            ISBN: "156898989X",
            img:"04371403_pinhole-cameras.jpg"
        },

        {
            id:122,
            title:"Vogue on Ralph Lauren",
            publisher:"Harry N. Abrams, Inc.",
            year: 2015,
            authors:["Kathleen Baird-Murray"],
            pages:158 ,
            language: "engleza",
            ISBN: "1419715895",
            img:"11054768_vogue-on-ralph-lauren.jpg"
        },

        {
            id:123,
            title:"Photobox: The Essential Collection: 250 Images You Need To See",
            publisher:"Thames & Hudson Ltd",
            year: 2016,
            authors:["Roberto Koch"],
            pages:512 ,
            language: "engleza",
            ISBN: "9780500292662",
            img:"455848-0-240.jpeg"
        },

        {
            id:124,
            title:"Polaroids: Mapplethorpe",
            publisher:"Prestel",
            year: 2016,
            authors:["Sylvia Wolf","Robert Mapplethorpe"],
            pages:256 ,
            language: "engleza",
            ISBN: "3791348701",
            img:"01365866_robert-mapplethorpe.jpg"
        },

        {
            id:125,
            title:"Street Photographer's Manual",
            publisher:"Thames & Hudson Ltd",
            year: 2014,
            authors:["David Gibson"],
            pages:192 ,
            language: "engleza",
            ISBN: "0500291306",
            img:"02428408_street-photographer-s-manual.jpg"
        },

        {
            id:126,
            title:"101 ponguri de baza in fotografia digitala",
            publisher:"Litera International",
            year: 2008,
            authors:["Michael Freeman"],
            pages:176 ,
            language: "romana",
            ISBN: "978-973-1838-65-6",
            img:"michael-freeman-101-ponturi-de-baza-in-fotogra-litera-international-2008-a-472107-510x510.jpg"
        },

        {
            id:127,
            title:"Scoala De Fotografie: Editarea Digitala",
            publisher:"Litera",
            year: 2015,
            authors:["Michael Freeman"],
            pages:160 ,
            language: "romana",
            ISBN: "9786066869836",
            img:"869401.jpg"
        },

        {
            id:128,
            title:"Scoala De Fotografie: Expunerea",
            publisher:"Litera",
            year: 2015,
            authors:["Michael Freeman"],
            pages:160 ,
            language: "romana",
            ISBN: "9786066869812",
            img:"9786066869812-2775332-240.jpg"
        },

        {
            id:129,
            title:"Scoala De Fotografie: Lumina si iluminarea",
            publisher:"Litera",
            year: 2015,
            authors:["Michael Freeman"],
            pages:160 ,
            language: "romana",
            ISBN: "9786066869805",
            img:"9786066869805-2775340-240.jpg"
        },

        {
            id:130,
            title:"Scoala De Fotografie: Compozitia",
            publisher:"Litera",
            year: 2015,
            authors:["Michael Freeman"],
            pages:160 ,
            language: "romana",
            ISBN: "9786066869829",
            img:"9786066869829-2775316-240.jpg"
        },

        {
            id:131,
            title:"Scoala De Fotografie: Compozitia",
            publisher:"Litera",
            year: 2011,
            authors:["Michael Freeman"],
            pages:640 ,
            language: "romana",
            ISBN: "9786066003568",
            img:"9786066003568-1900638-240.jpg"
        },

        {
            id:132,
            title:"Shooting Yourself",
            publisher:"Ilex",
            year: 2013,
            authors:["Haje Jan Kamps"],
            pages:176 ,
            language: "engleza",
            ISBN: "1781579946",
            img:"01353283_shooting-yourself.jpg"
        },

        {
            id:133,
            title:"Artists and Their Cats",
            publisher:"Chronicle Books",
            year: 2003,
            authors:["Alison Nastasi"],
            pages:112 ,
            language: "engleza",
            ISBN: "1452133557",
            img:"06794725_artists-and-their-cats.jpg"
        },

        {
            id:134,
            title:"Andre Kertesz: The Polaroids",
            publisher:"WW Norton & Co",
            year: 2007,
            authors:["Andre Kertesz"],
            pages:128 ,
            language: "engleza",
            ISBN: "0393065642",
            img:"04067313_andre-kertesz.jpg"
        },

        {
            id:135,
            title:"Creative Photography: The Professional Edge",
            publisher:"Octopus Publishing Group",
            year: 2019,
            authors:["Dan M. Lee"],
            pages:176 ,
            language: "engleza",
            ISBN: "1781575924",
            img:"20928708_creative-photography-the-professional-edge.jpg"
        },

        {
            id:136,
            title:"Foundation Course: Black & White Photography",
            publisher:"Ammonite Press",
            year: 2015,
            authors:["David Taylor"],
            pages:176 ,
            language: "engleza",
            ISBN: "978-1-78145-090-1",
            img:"blackandwhite.jpg"
        },

        {
            id:137,
            title:"Drumul matasii: O istorie in imagini",
            publisher:"Corint",
            year: 2018,
            authors:["Wang Qing"],
            pages:126 ,
            language: "romana",
            ISBN: "9786067934656",
            img:"234733046-0.jpeg"
        },

        {
            id:138,
            title:"100 Great Street Photographs",
            publisher:"Pretel",
            year: 2017,
            authors:["David Gibson"],
            pages:208 ,
            language: "engleza",
            ISBN: "9783791384382",
            img:"1605396925-0.jpeg"
        },

        {
            id:139,
            title:"Creative Portrait Photography",
            publisher:"PIXIQ",
            year: 2012,
            authors:["Natalie Dybisz"],
            pages:160 ,
            language: "engleza",
            ISBN: "1454704039",
            img:"12172422_creative-portrait-photography.jpg"
        },

        {
            id:140,
            title:"Boudoir Photography",
            publisher:"AMPHOTO",
            year: 2011,
            authors:["Critsey Rowe"],
            pages:159 ,
            language: "engleza",
            ISBN: "9780817400118",
            img:"11031857_boudoir-photography.jpg"
        },

        {
            id:141,
            title:"Fotografia de portret: 99 de idei de fotografie pentru tine, familie si prietenii tai",
            publisher:"Litera",
            year: 2010,
            authors:["Annabel Williams"],
            pages:144 ,
            language: "romana",
            ISBN: "9789736756382",
            img:"annabel-williams-litera.png"
        },

        {
            id:142,
            title:"Focus in Photography: Understand the Fundamentals, Explore the Creative, Take Beautiful Photos",
            publisher:"Ilex",
            year: 2016,
            authors:["John Neel"],
            pages:176 ,
            language: "engleza",
            ISBN: "9781781572382",
            img:"1035061-0-240.jpeg"
        },

        {
            id:143,
            title:"Urban Photography",
            publisher:"Ilex Press",
            year: 2019,
            authors:["Tim Cornbill"],
            pages:192 ,
            language: "engleza",
            ISBN: "9781781576120",
            img:"251254703-0-240.jpeg"
        },

        {
            id:144,
            title:"The Photographer's Eye Remastered 10th Anniversary: Composition and Design for Better Digital Photographs",
            publisher:"Ilex",
            year: 2017,
            authors:["Michael Freeman"],
            pages:192 ,
            language: "engleza",
            ISBN: "9781781574553",
            img:"1981274-0-240.jpeg"
        },

        {
            id:145,
            title:"Black & White Photography: The timeless art of monochrome in the post-digital age",
            publisher:"Ilex",
            year: 2017,
            authors:["Michael Freeman"],
            pages:192 ,
            language: "engleza",
            ISBN: "9781781573242",
            img:"1981273-0-240.jpeg"
        },

        {
            id:146,
            title:"The Photographer's Vision: Understanding and appreciating great photography",
            publisher:"Ilex",
            year: 2017,
            authors:["Michael Freeman"],
            pages:192 ,
            language: "engleza",
            ISBN: "9781781576892",
            img:"20466134_photographer-s-vision-remastered.jpg"
        },

        {
            id:147,
            title:"Hollywood Foto-Rhetoric",
            publisher:"Simon & Schuster",
            year: 2010,
            authors:["Bob Dylan","Barry Feinstein"],
            pages:162 ,
            language: "engleza",
            ISBN: "9781439112557",
            img:"08564441_hollywood-foto-rhetoric.jpg"
        },

        {
            id:148,
            title:"In spatele camerei",
            publisher:"Didactica Publishing House",
            year: 2018,
            authors:["Laura Magni"],
            pages:224 ,
            language: "romana",
            ISBN: "5948495000462",
            img:"270760906-1-240.jpeg"
        },

        {
            id:149,
            title:"100 de fotografii care au schimbat lumea",
            publisher:"Didactica Publishing House",
            year: 2017,
            authors:["Margherita Giacosa","Roberto Mottadelli","Gianni Morelli"],
            pages:224 ,
            language: "romana",
            ISBN: "5948489356315",
            img:"2329130-0-240.jpeg"
        },

        {
            id:150,
            title:"Sebastiao Salgado: Genesis",
            publisher:"Taschen GmbH",
            year: 2014,
            authors:["Lélia Wanick Salgado"],
            pages:520 ,
            language: "engleza",
            ISBN: "3836538725",
            img:"00166736_genesis-sebastiao-salgado.jpg"
        },

        {
            id:151,
            title:"Invazie Praga 68",
            publisher:"Art",
            year: 2013,
            authors:["Josef Koudelka"],
            pages:294 ,
            language: "romana",
            ISBN: "9789731249131",
            img:"9789731249131-2562539-240.jpg"
        },

        {
            id:152,
            title:"The Female Lead - Women Who Shape Our World",
            publisher:"Ebury Press",
            year: 2017,
            authors:["Edwina Dunn","Brigitte Lacombe"],
            pages:256 ,
            language: "engleza",
            ISBN: "9781785033520",
            img:"2325987-0-240.jpeg"
        },

        {
            id:153,
            title:"High Heels : Fashion, Femininity and Seduction",
            publisher:"Thames & Hudson Ltd",
            year: 2012,
            authors:["Ivan Vartanian"],
            pages:192 ,
            language: "engleza",
            ISBN: "978-0-500-51572-3",
            img:"003993536.jpg"
        },

        {
            id:154,
            title:"Robert Mapplethorpe - The Archive",
            publisher:"Getty Trust Publications",
            year: 2016,
            authors:["Frances Terpak"],
            pages:240 ,
            language: "engleza",
            ISBN: "1606064703",
            img:"02851776_robert-mapplethorpe-the-archive.jpg"
        },

        {
            id:155,
            title:"Atlasul frumusetii: Femeile lumii în 500 de portrete",
            publisher:"Humanitas",
            year: 2017,
            authors:["Mihaela Noroc"],
            pages:352 ,
            language: "romana",
            ISBN: "9789735075590",
            img:"1713463561-0-240.jpeg"
        },

        {
            id:156,
            title:"Photographer's Guide to Posing",
            publisher:"Rocky Nook",
            year: 2017,
            authors:["Lindsay Adler"],
            pages:448 ,
            language: "engleza",
            ISBN: "1681981947",
            img:"12447702_the-photographer-s-guide-to-posing-techniques-to-flatter-anyone.jpg"
        },

        {
            id:157,
            title:"How to Photograph Absolutely Everything",
            publisher:"Dorling Kindersley Ltd",
            year: 2009,
            authors:["Tom Ang"],
            pages:384 ,
            language: "engleza",
            ISBN: "9781405333078",
            img:"04200025_how-to-photograph-absolutely-everything.jpg"
        },

        {
            id:158,
            title:"Digital Photography Month by Month",
            publisher:"Dorling Kindersley Ltd",
            year: 2016,
            authors:["Tom Ang"],
            pages:360 ,
            language: "engleza",
            ISBN: "9780241238967",
            img:"1657453-0-240.jpeg"
        },

        {
            id:159,
            title:"Digital Photography Month by Month",
            publisher:"Dorling Kindersley Ltd",
            year: 2017,
            authors:["Tom Ang"],
            pages:360 ,
            language: "engleza",
            ISBN: "9780241241257",
            img:"1662278-0-240.jpeg"
        },

        {
            id:160,
            title:"Digital Photography Month by Month",
            publisher:"Dorling Kindersley Ltd",
            year: 2016,
            authors:["Tom Ang"],
            pages:362 ,
            language: "engleza",
            ISBN: "9780241226797",
            img:"91IctlB9LBL.jpg"
        },

        {
            id:161,
            title:"Photoshop Elements 2019 For Dummies",
            publisher:"John Wiley & Sons",
            year: 2018,
            authors:["Barbara Obermeier","Ted Padova"],
            pages:448 ,
            language: "engleza",
            ISBN: "9781119520153",
            img:"302800036-0-240.png"
        },

        {
            id:162,
            title:"Picture Perfect Posing",
            publisher:"Pearson Education",
            year: 2014,
            authors:["Roberto Valenzuela"],
            pages:336 ,
            language: "engleza",
            ISBN: "0321966465",
            img:"02476889_picture-perfect-posing.jpg"
        },

        {
            id:163,
            title:"Past Masters of the Nude",
            publisher:"Wolfbait",
            year: 2020,
            authors:["-"],
            pages:78 ,
            language: "engleza",
            ISBN: "1916215114",
            img:"25655623_past-masters-of-the-nude.jpg"
        },

        {
            id:164,
            title:"WORLD PRESS PHOTO 18",
            publisher:"Till Schaap Edition GmbH",
            year: 2017,
            authors:["-"],
            pages:240 ,
            language: "germana",
            ISBN: "978-3-03878-014-4",
            img:"20014109_world-press-photo-18.jpg"
        },


        {
            id:165,
            title:"World Press Photo 2019",
            publisher:"SCHILT PUBH",
            year: 2019,
            authors:["-"],
            pages:240 ,
            language: "engleza",
            ISBN: "9053309209",
            img:"20940277_world-press-photo-2019.jpg"
        },

        {
            id:166,
            title:"Ansel Adams. The Negative",
            publisher:"Ansel Adams",
            year: 2016,
            authors:["Robert Baker"],
            pages:288 ,
            language: "engleza",
            ISBN: "9780821221860",
            img:"15167322.jpg"
        },

        {
            id:167,
            title:"FOTOGRAFIA, REVISTA ASOCIATIEI \" FAR \" , ANUL II , NR. 5-6 , 1936",
            publisher:"-",
            year: 1936,
            authors:["-"],
            pages:288 ,
            language: "romana",
            ISBN: "",
            img:"fotografia-revista-asociatiei-far-anul-ii-nr-5-6-1936-p108688-0.jpg"
        },

        {
            id:168,
            title:"The BetterPhoto Guide to Creative Digital Photography: Learn to Master Composition, Color, and Design",
            publisher:"Amphoto Books",
            year: 2011,
            authors:["Jim Miotke","Kerry Drager"],
            pages:224 ,
            language: "engleza",
            ISBN: "978-0817424992",
            img:"61moINfilxL._SX390_BO1,204,203,200_.jpg"
        },

        {
            id:169,
            title:"Dancers After Dark",
            publisher:"Workman Publishing",
            year: 2016,
            authors:["Jordan Matter"],
            pages:240 ,
            language: "engleza",
            ISBN: "9780761189336",
            img:"1039468-0-240.jpeg"
        },

        {
            id:170,
            title:"One Face, Fifty Ways",
            publisher:"Ilex Press",
            year: 2017,
            authors:["Imogen Dyer","Mark Wilkinson"],
            pages:160 ,
            language: "engleza",
            ISBN: "9781781574300",
            img:"1987857-0-240.jpeg"
        },

        {
            id:171,
            title:"Focus and Filter",
            publisher:"Ulysses Pr",
            year: 2016,
            authors:["Andrew Darlow"],
            pages:200 ,
            language: "engleza",
            ISBN: "1612436137",
            img:"11128246_focus-and-filter.jpg"
        },

        {
            id:172,
            title:"The Complete Portrait Manual",
            publisher:"Weldon Owen, Incorporated",
            year: 2016,
            authors:["-"],
            pages:224 ,
            language: "engleza",
            ISBN: "161628952X",
            img:"3736086_product.jpg"
        },

        {
            id:173,
            title:"Despre fotografie",
            publisher:"Vellant",
            year: 2018,
            authors:["Susan Sontag"],
            pages:232 ,
            language: "romana",
            ISBN: "9786069800324",
            img:"85891383-0-240.jpeg"
        },

        {
            id:174,
            title:"Fotograful creator",
            publisher:"Polirom",
            year: 2015,
            authors:["Andreas Feininger"],
            pages:264 ,
            language: "romana",
            ISBN: "9789734652716",
            img:"9789734652716-2804031-240.jpg"
        },

        {
            id:175,
            title:"Avatar Photo Magazine Nr. 1",
            publisher:"-",
            year: 2022,
            authors:["-"],
            pages:67 ,
            language: "romana",
            ISBN: "2821-4927-01",
            img:"1613605409-5.jpeg"
        },

        {
            id:176,
            title:"Avatar Photo Magazine Nr. 2",
            publisher:"-",
            year: 2022,
            authors:["-"],
            pages:67 ,
            language: "romana",
            ISBN: "2821-4927-02",
            img:"APM-NR-2-724x1024.jpg"
        },

        {
            id:177,
            title:"Avatar Photo Magazine Nr. 3",
            publisher:"-",
            year: 2023,
            authors:["-"],
            pages:67 ,
            language: "romana",
            ISBN: "2821-4927-03",
            img:"2148909098-0.jpeg"
        },

        {
            id:178,
            title:"Avatar Photo Magazine Nr. 5",
            publisher:"-",
            year: 2024,
            authors:["-"],
            pages:67 ,
            language: "romana",
            ISBN: "2821-4927-05",
            img:"avatar-photo-magazine-nr-4-revista-educatie-vizuala-copie-504909.jpeg"
        },

        {
            id:179,
            title:"Imagine Magazine Vol. VIII / 2024",
            publisher:"QLT Studio 2",
            year:2024,
            authors:["-"],
            pages:290,
            language: "engleza",
            ISBN: "2734-5718",
            img:"IMAGINE-Nr-8-2024.jpg"
        },

        {
            id:180,
            title:"Imagine Magazine Vol. I / 2020",
            publisher:"QLT Studio 2",
            year:2020,
            authors:["-"],
            pages:228,
            language: "romana",
            ISBN: "2734-5718",
            img:"img.png"
        },


        {
            id:181,
            title:"Imagine Magazine Vol. II / 2020",
            publisher:"QLT Studio 2",
            year:2020,
            authors:["-"],
            pages:226,
            language: "romana",
            ISBN: "2734-5718",
            img:"img_1.png"
        },

    ]

    handleSearch(event){
        let search = event.target.value;

        // let filtredBooks = ITBooksContent.books.filter((item) =>
        //     item.title.toLowerCase().includes(search.toLowerCase())
        // );
        //
        // this.setState({filtredBooks: filtredBooks});

        this.setState({search: search});
    }

    render() {

        this.state.filtredBooks = PhotoBooksContent.books;

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <label htmlFor="search">
                        Cautare:&nbsp;
                        <input id="search" type="text" onChange={this.handleSearch.bind(this)} />
                    </label>
                    &nbsp;
                    <span>Numar total carti: {PhotoBooksContent.books.length}</span>
                </div>

                <hr/>

                <br/>
                <div className={"text-justify important"}>

                    {PhotoBooksContent.books
                        .filter(
                            item => {
                                let s = this.state.search.toLowerCase();
                                let okTitle = item.title.toLowerCase().includes(s);
                                let okPublisher = item.publisher.toLowerCase().includes(s);
                                let okAuthors = item.authors.some(a=>a.toLowerCase().includes(s));
                                return okTitle || okPublisher || okAuthors;
                            }
                        )
                        .map(function(item, index){
                        return <div className="row">

                            {/*<div className="col-sm-4">*/}
                            {/*    <img alt={""} style={{width:220}} className={"rounded mx-auto d-block responsive-img"}*/}
                            {/*         src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>*/}

                            {/*</div>*/}

                            <div className="col-sm-12">

                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index+1}</span>
                                <table className="table table-sm table-hover table-dark">
                                    <tbody>
                                    <tr>
                                        <td width={250} rowSpan={"7"}>
                                            <img alt={""} style={{width:220}} className={"rounded mx-auto d-block responsive-img"}
                                                    src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>
                                        </td>

                                        <td width={250}>
                                            <b>Titlu</b>
                                        </td>
                                        <td>
                                            <span className="card-text" dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Editura</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.publisher}}/></td>
                                    </tr>
                                    <tr>
                                        <td><b>An</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.year}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Autori</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.authors}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Numar pagini</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.pages}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Limba</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.language}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>ISBN</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.ISBN}}/></td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>;

                    })}

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PhotoBooksContent;