import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"books-root", url:"#", title:"Carti", subtitle:""},

        {id:"books-it", url:"/books/it", title:"Carti IT", subtitle:"", parent:"books-root"},
        {id:"books-it-old", url:"/books/it-old", title:"Carti IT (vechi)", subtitle:"", parent:"books-root"},
        {id:"books-photo", url:"/books/photo", title:"Carti Fotografie", subtitle:"", parent:"books-root"},
        {id:"books-magazine-photo", url:"/books/magazine-photo", title:"Reviste Fotografie", subtitle:"", parent:"books-root"},
        {id:"books-video", url:"/books/video", title:"Carti Videografie", subtitle:"", parent:"books-root"},
        {id:"books-art", url:"/books/art", title:"Carti Arta", subtitle:"", parent:"books-root"},
        {id:"books-math", url:"/books/math", title:"Carti Matematica", subtitle:"", parent:"books-root"},
    ]

    static indexUrl = "/books/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Tips & Tricks
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;